export default {
  banner: {
    title: '全渠道訊息觸及平台',
    subTitle: '快速構建您的訊息管理平台，實現多渠道一致化的訊息傳送、管理和統計',
    mainBtn: '免費試用',
    subBtn: '聯係銷售',
  },
  scene: {
    title: '全域觸及能力',
    subTitle:
      'EngageLab 整合觸達用戶的主流渠道，可透過 Notify API 和 ConverStations API，助力您快速構建用戶觸達系統。多渠道行銷平台和解決方案，有助於企業更輕易觸達處於不同接觸點的客戶',
  },
  reason: [
    {
      title: '更多渠道，無處不達',
      desc: [
        '多渠道行銷平台包括 APP 推播、郵件、簡訊、Whatsapp 等，支援多渠道觸達客戶',
        '穩定可靠的自建渠道',
        ' 主流渠道全覆蓋 ',
        ' 快速串接',
        ' 易用的多元化 API 介面',
      ],
    },
    {
      title: '智慧傳送策略',
      desc: [
        '多渠道訊息傳送平台具備智慧通訊 API，能以更智慧方式實現更高訊息送達率',
        '多渠道重發',
        ' 多渠道分發',
        ' 靈活的策略配置',
        ' 更低的傳送成本',
      ],
    },
    {
      title: '全鏈路數據分析',
      desc: [
        '多渠道訊息傳送平台可以分析客戶互動資料，有助於您制定客製化行銷解決方案和後續計劃',
        '訊息全生命週期管理',
        ' 訊息折損分析',
        ' 用戶訊息行為分析',
        ' 推播成效分析與營運優化',
      ],
    },
  ],
  function: {
    title: '強大易用的 API',
    subTitle: '您的應用程式可以利用標準 REST API 調用所有關鍵功能。以下功能有助於您更輕鬆營運多渠道行銷平台，成功觸達您的客戶',
    sub: [
      {
        title: '一般訊息傳送',
        text: '自由選擇傳送渠道，訊息內容格式智慧校正，以遵循各渠道規則范',
      },
      {
        title: '範本訊息傳送',
        text: '若透過範本提前預設訊息格式和變量，傳送訊息時，只需一次變量傳值，即可用於所有渠道',
      },
      {
        title: '訊息撤銷',
        text: '支援撤銷 24 小時內發出的訊息，避免因人為或其他因素造成傳送意外',
      },
      {
        title: '用戶管理',
        text: '管理用戶的多渠道聯繫方式、用戶標籤等資訊，用於重發、分發和統計分析',
      },
      {
        title: '素材系統',
        text: '對多渠道的圖片、影片、檔案等素材進行統一管理，用於郵件附件、多元化媒體訊息傳送',
      },
      {
        title: '批量請求',
        text: '一次請求支援批量設定多個目標用戶及其相應訊息內容，以減輕企業伺服器的請求壓力',
      },
    ],
  },
}
