export default {
  banner: {
    title: '遍及全球的簡訊觸及能力',
    subTitle: '透過 EngageLab 簡易整合型簡訊，以及互動式個人化解決方案，輕鬆觸達全球客戶',
    mainBtn: '免費試用',
    subBtn: '聯係銷售',
  },
  scene: {
    title: '滿足各類場景的簡訊類型',
    subTitle:
      '透過我們智慧簡訊行銷平台，不但能高效率觸達客戶，亦可促進業務發展。此外，EngageLab 還提供 4 種不同的簡訊行銷解決方案，足以滿足多樣需求，促進與客戶的互動',
    sub: [
      {
        title: '驗證碼',
        text: '10秒觸及，品質卓越的專用渠道，金融級安全保護；適用於註冊登入驗證、找回密碼、支付認證等場景',
      },
      {
        title: '行銷推廣',
        text: '支援多元的推廣簡訊樣式、大容量高並發處理，為高峰期傳送簡訊提供保障；適用於電商活動、新品宣傳、會員服務等',
      },
      {
        title: '服務通知',
        text: '送達率 99％，即時監控智慧調度，24 小時全天候維運保障；適用於日程通知、活動會議邀請、企業資訊公告等',
      },
      {
        title: '交易簡訊',
        text: '透過 EngageLab 簡訊行銷解決方案，向客戶傳送交易簡訊，輕鬆確認訂單、發票資訊、物流通知等，減少人力成本的同時，還能提供更優質的客戶服務',
      },
    ],
  },
  reason: [
    {
      title: '全球傳送能力',
      desc: [
        'EngageLab 簡訊行銷解決方案能合法地觸達世界各地客戶，有利於您的全球業務發展',
        '透過 API，直連 200+ 國家/地區的營運商簡訊服務',
        '嚴格遵守全球安全隱私權法規，包括國際 GDPR、CCPA 等法規',
      ],
    },
    {
      title: '智慧傳送策略',
      desc: [
        '我們智慧傳送策略，能滿足您在簡訊傳送速度、送達率和成本效益方面的多項要求。此外，簡訊行銷解決方案極具彈性，能夠為客戶傳送客製化簡訊',
      ],
    },
    {
      title: '自動化技術',
      desc: [
        'EngageLab 簡訊行銷解決方案兼具智慧化和自動化功能，可確保資訊安全性和送達效率',
        '建置全球監控系統，優化和排除渠道效能故障',
        '透過彈性伺服器和渠道，實現更快捷的更高傳輸量',
        '先進的安全系統，保護您和您的客戶免受詐騙和垃圾簡訊的侵害',
      ],
    },
  ],
  function: {
    title: '快速穩定的簡訊傳送服務',
    subTitle:
      'EngageLab 可以提供快捷、穩定的簡訊傳送服務和行銷解決方案，滿足客戶在不同場景下的多元需求。您可以透過高效率的簡訊服務 API，更順利地與客戶互動，促進業務發展',
    sub: [
      {
        title: '全球傳送',
        text: '品質卓越的簡訊渠道，5 秒觸及用戶，99% 送達率',
      },
      {
        title: '多渠道自動切換',
        text: '擁有多元化簡訊渠道資源及智慧化維運繫統，能夠自動切換渠道，確保簡訊傳送的穩定性',
      },
      {
        title: '即時簡訊狀態回條',
        text: '完整的簡訊傳送紀錄和狀態紀錄查詢，透明化計費統計',
      },
      {
        title: '高並發處理',
        text: '採用億級推播業務的技術架構作為服務支援，為高峰期傳送簡訊提供保障',
      },
      {
        title: '支援上行回覆',
        text: '支援簡訊的上行回覆',
      },
      {
        title: '結合推播降低成本',
        text: '針對推播訊息未送達的用戶重寄簡訊，既能確保訊息切實觸達，又能省下寶貴的成本',
      },
    ],
  },
}
