export default {
  title: '聯繫銷售',
  content: '如需更詳細的產品介紹，請聯繫我們',
  sub: [
    { name: '名', placeholder: '您的名', errTip: '請輸入您的名' },
    { name: '姓', placeholder: '您的姓', errTip: '請輸入您的姓' },
    { name: '公司名稱', placeholder: '您的公司名稱', errTip: '請輸入您的公司名稱' },
    { name: '其他信息', remark: '可選', placeholder: '請說明您的要求，我們的顧問將與您聯繫' },
  ],
  sub1: [
    { name: '聯係人姓名', placeholder: '聯係人姓名', errTip: '請輸入聯係人姓名' },
    { name: '國家', placeholder: '國家', errTip: '請選擇國家' },
    { name: '其他信息', placeholder: '其他信息（可選）' },
  ],
  left: {
    title: 'EngageLab助您一臂之力，全方位觸及客戶',
    sub: ['全球高送達率', '百億訊息傳送量級', '方便好用的開發者 API', '專業技術支援'],
  },
  modeTitle: '聯係方式',
  mode: [
    {
      name: '電子郵件',
      placeholder: 'name@company.com',
      errTip: '請輸入您的電子郵件',
      errTip1: '請輸入正確的電子郵件地址',
    },
    {
      name: '電話號碼',
      placeholder: '您的電話號碼',
      errTip: '請輸入您的電話號碼',
    },
    {
      name: 'WhatsApp',
      placeholder: '您的WhatsApp',
      errTip: '請輸入您的WhatsApp',
    },
    {
      name: 'skype',
      placeholder: '您的skype',
      errTip: '請輸入您的skype',
    },
    {
      name: 'line',
      placeholder: '您的line',
      errTip: '請輸入您的line',
    },
    {
      name: 'Telegram',
      placeholder: '您的Telegram',
      errTip: '請輸入您的Telegram',
    },
  ],
  btn: '提交',
  successTitle: '提交成功！',
  successText: '我們會盡快與您聯繫',
}
