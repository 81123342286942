export default {
  title: '開始使用 Engagelab',
  tips: {
    signTips: ['沒有帳戶？', '前去註冊'],
    splitTip: '或',
    rememberTip: '記住密碼',
    forgetTip: '忘記密碼',
  },
  formInfo: {
    email: {
      name: '電子郵件位址',
      placeholder: '建議使用公司電子郵件信箱',
      errTip: '請輸入您的工作電子郵件',
      errTip1: '電子郵件無效',
      errTip2: '電子郵件信箱位址不存在，是否',
    },
    password: {
      name: '密碼',
      placeholder: '大小写字母+數字組合，長度不少於8個字元',
      errTip: '大小写字母+數字組合，長度不少於8個字元',
      errTip1: '包含至少8個字元',
      errTip2: '不符合包含小寫字母(a-z)和大寫字母(A-Z)的條件',
      errTip3: '不符合至少包含一個數字(0-9)或一個符號的條件',
    },
  },
  muchTip: '登入失敗次數過多，請5分鐘後再試',
  btn: '登入',
}
