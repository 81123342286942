export default {
  title: '您組織的名稱是什麼？',
  title1: '建立組織',
  title2: '綁定組織',
  title3: '綁定成功',
  tips: {
    signTips: ['已經有了帳戶？', '登入'],
    signTips1: ['已經有了組織？', '去綁定'],
    signTips2: ['沒有組織？', '去建立'],
    signTips3: ['返回EDM'],
  },
  formInfo: {
    name: {
      name: '組織名稱',
      placeholder: '組織名稱',
      errTip: '請輸入組織名稱',
      errTip1: '長度少於 50 個字元',
      errTip2: '組織名稱已存在',
    },
    website: {
      name: '組織網站',
      placeholder: '組織網站',
      errTip: '請輸入組禁止使用myshopify.com相關鏈接作爲組織網址織網站',
    },
    timeZone: {
      name: '組織時區',
      tip: '（选定后将不可更改）',
      errTip: '請輸入組織時區',
    },
  },
  btn: '建立組織',
}
