export default {
  lastUpdated: '最新更新',
  catalog: '本頁目錄',
  all: '全部',
  noData: '暫無搜索結果',
  noSearchResult: '暫無結果',
  documentation: '檔案',
  switchThemeLightTips: '點擊切換淺色背景',
  switchThemeDarkTips: '點擊切換深色背景',
  popThemeTips: '點擊在小浮窗顯示',
  codePop: '代碼浮窗',
  codePopShow: '此代碼塊在浮窗中顯示',
  closePop: '點擊關閉彈窗',
  copyCodeTips: '點擊複製代碼',
  copySuccess: '複製成功',
  darkTheme: '深色',
  lightTheme: '淺色',
  searchDocs: '搜索文檔',
}
