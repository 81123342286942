export default {
  home: 'EngageLab',
  all: '所有',
  hot: '熱點新聞',
  other: '相關文章',
  blog: '部落格',
  text: '文章詳情',
  search: '輸入關鍵詞搜索',
  noData: '暫無數據',
  searchBtn: '蒐索',
  seo: {
    title: '部落格-Engagelab',
    keysStr: '客戶互動平台,通訊 API,Engagelab',
    desc: '閱讀Engagelab的文章，了解Engagelab的最新消息、產品等，點擊了解更多詳情！',
  },
}
