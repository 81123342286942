export default {
  banner: {
    title: '全球領先的客戶互動平台',
    subTitle:
      'EngageLab 可以提供強大的客戶互動平台和多渠道訊息傳送解決方案，有助於企業實現精準的客戶觸達策略和更高訊息送達率，將用戶轉化率提升至最高',
    mainBtn: '免費試用',
    subBtn: '聯係銷售',
  },
  advant: {
    title: '領先業界的技術與服務優勢',
    subTitle:
      'EngageLab 客戶互動平台擁有成熟的產品化能力、多元化產品佈署、完善的開發人員工具包以及領先業界的解決方案，可以透過不同渠道與客戶互動',
    sub: [
      {
        title: '全球高送達率',
        text: '亞洲乃至全球送達率領先的通知訊息服務商之一',
      },
      {
        title: '百億訊息傳送量級',
        text: '日均百億級訊息傳送規模，系統服務穩定可靠',
      },
      {
        title: '方便好用的開發者 API',
        text: '透過完善的 API 介面和簡單易用的 SDK，短時間內輕鬆啟用我們的服務',
      },
      {
        title: '專業技術支援',
        text: '由專業技術專家組成的服務團隊，為開發者提供技術支援服務',
      },
    ],
  },
  products: {
    title: '助您一臂之力，全方位觸及客戶',
    subTitle:
      'EngageLab 整合觸達客戶的主流渠道，可透過智慧傳送策略，助力企業以更低成本快速觸達客戶。全面客戶互動解決方案可根據不同需求進行客製化，進一步提升客戶互動率',
    sub: [
      {
        title: 'Omnichannel Messaging',
        text: '快速構建您的訊息管理平台，實現多渠道一致的訊息傳送、管理和統計',
      },
      {
        title: 'Apppush',
        text: '3 分鐘快速整合 SDK，實現智慧化精準推播，有效提升用戶活躍度和留存率',
      },
      {
        title: 'Webpush',
        text: '不再需要依賴移動APP，您可以隨時向網站訂閱者推播營銷消息和服務通知，提升用戶活躍和轉化。',
      },
      {
        title: 'SMS',
        text: '簡訊觸及能力遍及全球，有助於企業快速實現全球簡訊傳送',
      },
      {
        title: 'Email',
        text: '提供高效率穩定的電子郵件傳送 API，並透過即時準確數據回饋，優化傳送策略以提高送達率',
      },
      {
        title: 'WhatsApp Business API',
        text: '透過 WhatsApp Business API，與超過 20 億的全球用戶建立更加緊密的自然聯繫',
      },
    ],
  },
  access: {
    title: '專為開發者設計的極簡串接流程',
    subTitle: '簡潔易用的通訊 API 設計、安全穩定的 SDK 和完善的開發文件，有助於開發人員快速存取服務，善用 EngageLab 客戶互動平台',
  },
  comments: {
    title: '深獲全球眾多企業的信賴',
    subTitle: 'EngageLab 憑藉成熟穩定的技術及專業貼心服務，深獲眾多客戶的信賴及支持',
    sub: [
      {
        name: 'Tyler Elise',
        profession: '產品負責人',
        text: 'EngageLab 多渠道訊息傳送服務能縮短產品研發時間，為我們提供簡單易用的解決方案和方便快捷的客戶互動平台，進而優化用戶體驗',
      },
      {
        name: 'Arika Johnson',
        profession: '客戶分析團隊負責人',
        text: '我們選擇 EngageLab，因為它具備出色的多渠道訊息傳送能力，以及跨多重全球渠道的彈性互動解決方案，有助於我們更輕鬆地觸達全球不同地區的客戶',
      },
      {
        name: 'James Maddison',
        profession: '增長高級總監',
        text: 'EngageLab 是一個簡單好用的直觀互動式客戶互動平台。我們非常感謝 EngageLab 客戶支援團隊提供的專業支援服務',
      },
      {
        name: 'Robert Glaser',
        profession: '增長高級總監',
        text: '我們選擇 EngageLab，因為它的客戶互動平台有助於我們實現高效率的訊息傳送，同時根據複雜和高容量的訊息傳送需求進行資料導向決策，有助於我們提高用戶獲取率、轉化率、互動率和留存率',
      },
    ],
  },
  metaBanner: {
    title: 'WhatsApp 大中華區戰略合作夥伴',
    subTitle: 'EngageLab 提供官方授權的 WhatsApp 消息通道，快速連接全球 20 億 WhatsApp 用戶',
  },
}
