export default {
  banner: {
    title: 'WhatsApp Business API',
    subTitle: '身為 WhatsApp Business 解決方案提供商，EngageLab 助力企業使用 WhatsApp Business API 與全球二十多億名用戶建立密切聯繫',
    // mainBtn: '用 Facebook 登入',
    mainBtn: '免費試用',
    subBtn: '聯係銷售',
  },
  scene: {
    title: '多元化 WhatsApp Business API 訊息功能',
    sub: [
      {
        title: '文字訊息',
        text: '支援向用戶傳送純文字+Emoji 表情符號訊息',
      },
      {
        title: '視訊',
        text: '支援向用戶傳送視訊留言，並可直接在應用程式中播放',
      },
      {
        title: '圖片',
        text: '支援向用戶傳送圖文訊息，支援 PNG、JPG、JPEG 圖片格式',
      },

      {
        title: '互動式按鈕',
        text: '互動按鈕支援設定不同的文案和觸發策略，以便引導用戶順利轉化',
      },
      {
        title: '附件',
        text: '支援向用戶傳送附件訊息，例如 PDF 或其他附件',
      },
      {
        title: '地點',
        text: '支援向用戶傳送位置訊息，包含經度和緯度坐標',
      },
    ],
  },
  reason: [
    {
      title: '安全驗證',
      desc: [
        '安全性是用戶註冊、登入和支付流程的首要之務。WhatsApp OTP 服務能有效節省訊息傳送成本和時間，提升訊息送達率，增強資訊安全性並優化用戶體驗。透過 WhatsApp 獲得的用戶可轉化為更多商機。在 WhatsApp 行銷活動中，企業可使用單次密碼確保用戶安全',
      ],
    },
    {
      title: '爭取新客戶',
      desc: [
        '藉助嵌入式進入點，用戶能透過 WhatsApp Message API，在多個渠道發起 WhatsApp 會話，包括 Google 或 Facebook 廣告、電商網站等自營渠道、應用程式或郵件簽名或話務中心系統。這些方式有助於提升用戶體驗，使溝通更有效率，提高用戶互動率和留存率',
      ],
    },
    {
      title: '用戶持續轉化',
      desc: [
        'WhatsApp 行銷活動是提高用戶轉化率的有效方式。使用 WhatsApp，可吸引用戶對新產品、品牌和折扣促銷以及專場行銷活動的關注。我們解決方案有助於提升客戶互動率、生命週期價值 (LTV) 和回購率。此外，還可透過會員計劃、獎勵積分、限時優惠券和會員資格到期提醒等操作，喚醒流失的用戶',
      ],
    },
    {
      title: '重要通知',
      desc: [
        'WhatsApp Message API 透過 WhatsApp 傳送帳戶更新、交易、物流資訊和服務的重要通知，利用平台的高用戶互動率，確保用戶不會錯過重要任務資訊',
      ],
    },
  ],
  passageway: {
    title: '強大的 WhatsApp Business 解決方案',
    sub: [
      {
        title: '全域渠道服務',
        desc: 'WhatsApp 渠道能與 EngageLab 其他渠道產品一起使用，為用戶提供全域多渠道訊息服務',
      },
      {
        title: '功能強大的 API',
        desc: '採用 WhatsApp for Business 解決方案，有助於企業盡可能拉近與受眾的距離，進行即時溝通',
      },
      {
        title: '數據安全保障',
        desc: '身為 Meta (Facebook) 官方授權服務商，EngageLab 會將數據託管於我們內部數據中心。官方支援的 API 介面符合 GDPR 標準，並透過 WhatsApp 端對端加密確保安全性',
      },
    ],
  },
  steps: {
    title: '如何快速開始使用',
    sub: [
      {
        title: '註冊帳號',
        text: '註冊一個免費 EngageLab帳號',
      },
      {
        title: '開通 API',
        text: '登入 EngageLab 後台，申請開通 WhatsApp business API 權限，Meta 官方亦會參與審核相關資訊',
      },
      {
        title: '開始使用',
        text: '審核通過且開通服務後，即可透過 REST API 開發、調試並正式發佈您的應用程式',
      },
    ],
  },
}
