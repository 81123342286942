export default {
  banner: {
    title: '智慧化精準訊息推播',
    subTitle: 'EngageLab 推播通知服務可在 3 分鐘內快速整合 SDK，利用準確的智慧推播通知，提高用戶互動率和留存率',
    mainBtn: '免費試用',
    subBtn: '聯係銷售',
  },
  tab: {
    title: '應用程式場景',
    sub: [
      {
        title: '用戶行為觸發通知',
        descTitle: '身為一家推播通知服務供應商，EngageLab 可為您提供高效率的推播通知服務，並根據作業系統的追蹤用戶行為，適時地自動推播通知',
        desc: ['向新註冊用戶自動推播歡迎語', '向支付猶豫期用戶發放現金抵用券', '向棄單用戶推薦興趣物品'],
      },
      {
        title: '用戶分眾式通知',
        desc: [
          '行動推播通知服務可根據交易歷史、用戶標籤、用戶畫像、興趣特徵和價值評分，向用戶推播特定產品和服務通知，提高客戶生命週期價值（LTV）和用戶黏著度',
        ],
      },
      {
        title: '服務變動式通知',
        desc: [
          '如果用戶狀態發生變化，推播通知服務會根據後端業務系統的用戶狀態變化，推播交易支付、物流進度、帳戶異動、投訴回應、好友訊息等重要通知訊息',
        ],
      },
      {
        title: '地理位置式通知',
        desc: ['行動推播通知服務採用地理柵欄技術。觸發後，可根據用戶位置，推薦附近的咖啡館、酒店或餐廳'],
      },
    ],
  },
  reason: [
    {
      title: '如何實現高送達率',
      desc: [
        '由於 Google 服務覆蓋範圍有限和 Android 生態碎片化等問題，許多通知訊息無法送達客戶。Engagelab 推播通知解決方案和服務不僅支援 FCM、APNS 系統渠道，還支援企業的自建訊息傳送渠道，以及手機廠商的訊息傳送渠道（小米、華為、OPPO、vivo、魅族、榮耀等），有助於企業實現全球觸達目標，將訊息送達率大幅提升約 40%',
      ],
    },
    {
      title: '多元化訊息樣式',
      desc: [
        'EngageLab 推播通知服務支援多樣化通知樣式，包括通知欄、大型文字、大型圖片、通知隱藏式選單、全螢幕通知、資料流程、快顯視窗和自訂樣式，能有效吸引用戶注意，提高訊息點擊率',
      ],
    },
    {
      title: '精準找到推播目標用戶',
      desc: ['推播通知服務可透過用戶別名、標籤、地理柵欄、裝置標籤和為用戶建立群組，推播特定個人化通知訊息，大幅度提升訊息點擊率'],
    },
    {
      title: '完善的數據統計分析',
      desc: [
        '為了協助開發人員提高應用程式的用戶觸達率，EngageLab 可在整個訊息生命周期內提供完整資料分析服務，包括特定平台/渠道的訊息漏斗分析和損耗分析、有關新增用戶、活躍用戶、應用程式解除安裝、通知啟用/停用、訊息送達率、點擊率的統計資料以及其他後效分析。EngageLab 可以根據您的客戶生命週期，優化行動推播服務',
      ],
    },
  ],
  passageway: {
    title: '建置連結客戶的訊息渠道',
    subTitle:
      '我們推播通知服務可協助開發人員在最合適的時間，以最適當的方式，針對最精準的目標群體，傳送最合適的訊息，提高用戶互動率和轉化率',
    sub: [
      {
        title: '多平台支援',
        desc: '全面相容和支援 Android、iOS、Web、MacOS、Windows 多個平台，快速整合 SDK，為您的應用程式構建強大的推播能力',
      },
      {
        title: '高送達率',
        desc: 'FCM 服務在應用程式程序離線時，即無法正常送達通知訊息。透過智慧傳送策略，優先選擇送達率更高的裝置廠商推播渠道，有效提高訊息送達率',
      },
      {
        title: '高效率且穩定',
        desc: '日超百億級訊息傳送量，高並發、高可靠的國際專線，再加上多點備份功能，可確保系統實現高效率和穩定安全性',
      },
    ],
  },
}
