export default {
  banner: {
    title: '電子郵件傳送平台',
    subTitle: '郵件傳送 API 是高效率又穩定的郵件行銷利器。它可透過即時準確的資料回饋，優化郵件傳送策略，提高送達率',
    mainBtn: '免費試用',
    subBtn: '聯係銷售',
  },
  scene: {
    title: '全球傳送電子郵件的能力',
    subTitle:
      '只需花費您數分鐘的時間，即可快速整合我們郵件行銷 API。EngageLab 郵件行銷解決方案可向全球用戶快速傳送郵件，平均 3 秒送達，還提供詳細的資料分析報告',
  },
  reason: [
    {
      title: '更高的送達率',
      desc: [
        'EngageLab 可提供郵件傳送平台和全方位郵件行銷解決方案，提高郵件送達率',
        '助您配置發信網域名稱的 SPF、DKIM、DMARC 等記錄，建立可信賴的關係',
        'IP 集區自主維護，和電子郵件服務商建立合作，為您提供優質傳送渠道',
        '黑名單過濾攔截機制，降低無效Email地址投遞率',
        '內容分類，分渠道傳送，讓重要的電子郵件不再丟失',
      ],
    },
    {
      title: '更快的送達速度',
      desc: [
        'EngageLab 郵件行銷 API 能在數秒內不間斷地傳送郵件。EngageLab 郵件 API 操作方便，功能強大，可以隨時處理大量郵件，有助於您適時地輕鬆觸達客戶',
        '充分掌握主流電子郵箱服務商的收信策略，採用彈性傳送佇列',
        '24h 全天候監控，分析傳送情況，並持續自動優化傳送調度機制',
        '根據用戶的業務需求，提供充足的傳送 IP，加快投遞效率',
      ],
    },
    {
      title: '細微的行為數據追蹤',
      desc: [
        '智慧郵件行銷 API 可助您分析用戶行為和送達資料，不斷優化郵件行銷解決方案',
        '沒有數據盲點，提供多樣化、多維度統計',
        '自動解析傳送日誌、歸類失敗原因，以優化傳送效果',
        '提供電子郵箱服務商收信趨勢、郵件閱讀位置及裝置的分析數據',
      ],
    },
  ],
  passageway: {
    title: '專業的電子郵件傳送服務',
    subTitle: '構建安全、高效率、穩定的郵件傳送平台，有助於企業優化郵件行銷策略，透過提升用戶黏著度，提高用戶轉化率',
    sub: [
      {
        title: '高效率',
        desc: '功能完備的 API；Python、Java、Ruby 等多語言代碼範例，使您的開發工作更有效率',
      },
      {
        title: '安全',
        desc: '隱私數據漂白，關鍵操作 2FA 驗證；可配置介面調用 IP 白名單；全站 SSL 加密',
      },
      {
        title: '穩定',
        desc: '服務穩定性高達 99.99%；為您提供 7×24h 全天候客戶支援；分散部署',
      },
    ],
  },
}
