export default {
  title: '查看您的電子郵件',
  tips: {
    signTips: ['已經有帳戶了？', '登入'],
    emailSendTip: '我們已向您傳送電子郵件。請檢查電子郵件中的驗證碼資訊',
    countDownTip: ['請於', '秒後重新取得'],
    retryTip: '重新取得',
  },
  formInfo: {
    verifyCode: {
      name: '驗證碼',
      placeholder: '請輸入驗證碼',
      errTip: '請輸入驗證碼',
      errTip1: '請正確輸入驗證碼',
    },
  },
  btn: '下一步',
}
