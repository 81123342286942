export default {
  title: '重置密碼',
  tips: {
    signTips: ['已經有帳戶了？', '前去登入'],
  },
  formInfo: {
    password: {
      name: '密碼',
      placeholder: '大小写字母+數字組合，長度不少於8個字元',
      errTip: '大小写字母+數字組合，長度不少於8個字元',
      errTip1: '包含至少8個字元',
      errTip2: '不符合包含小寫字母(a-z)和大寫字母(A-Z)的條件',
      errTip3: '不符合至少包含一個數字(0-9)或一個符號的條件',
    },
    passwordAgain: {
      name: '再次輸入密碼',
      placeholder: '大小写字母+數字組合，長度不少於8個字元',
      errTip: '大小写字母+數字組合，長度不少於8個字元',
      errTip1: '包含至少8個字元',
      errTip2: '不符合包含小寫字母(a-z)和大寫字母(A-Z)的條件',
      errTip3: '不符合至少包含一個數字(0-9)或一個符號的條件',
      errTip4: '兩次輸入密碼不一致',
    },
  },
  btn: '下一步',
}
