export default {
  title: `雙因子驗證`,
  subTitle: `你的賬戶已開啟雙重驗證功能，因此你必須完成此額外的登錄步驟。`,
  error: `請輸入有效驗證碼。`,
  tip: `請輸入谷歌驗證器app提供的驗證碼`,
  contact1: `聯繫我們的`,
  contact2: `客服`,
  return: `返回`,
  submit: `提交驗證`,
}
