export default {
  title: '找回密碼',
  subTitle: '輸入您的電子郵件位址，以請求密碼重設。您將收到一封包含詳細說明的電子郵件。',
  tips: {
    signTips: ['已經有帳戶了？', '前去登入'],
  },
  formInfo: {
    email: {
      name: '電子郵件',
      placeholder: '電子郵件地址',
      errTip: '請輸入電子郵件地址',
      errTip1: '電子郵件無效',
      errTip2: '電子郵件信箱位址不存在，是否',
    },
  },
  btn: '下一步',
}
