export default {
  title: '建立您的 Engagelab',
  addPhoneTip: '為了保障您的帳號安全，及時接收系統緊急通知，請完善個人資訊。',
  addInfoTitle: '完善資訊',
  getCode: '獲取驗證碼',
  refresh: '刷新',
  sure: '確定',
  firstName: '名字',
  lastName: '姓氏',
  nameTip: '請輸入名字',
  nameTip2: '請輸入姓氏',
  tips: {
    signTips: ['已經有帳戶了？', '登入'],
    splitTip: '或',
    servicePolicy: '服務協議',
    privacy: '隱私權協議',
    assistTips: ['按一下「建立帳戶」，即表示我已閱讀並同意我們', '和'],
  },
  formInfo: {
    email: {
      name: '電子郵件位址',
      placeholder: '建議使用公司電子郵件信箱',
      errTip: '請輸入您的工作電子郵件',
      errTip1: '電子郵件無效',
      errTip2: '已有其他帳戶使用了此電子郵件位址',
    },
    password: {
      name: '密碼',
      placeholder: '大小写字母+數字組合，長度不少於8個字元',
      errTip: '大小写字母+數字組合，長度不少於8個字元',
      errTip1: '包含至少8個字元',
      errTip2: '不符合包含小寫字母(a-z)和大寫字母(A-Z)的條件',
      errTip3: '不符合至少包含一個數字(0-9)或一個符號的條件',
    },
    phone: {
      name: '電話號碼',
      placeholder: '請輸入電話號碼',
      errTip: '請輸入有效的電話號碼',
    },
    code: {
      name: '短信驗證碼',
      placeholder: '請輸入驗證碼',
      errTip: '驗證碼為空或錯誤',
    },
  },
  btn: '建立帳戶',
}
