import index from './zh-TW/index'
import push from './zh-TW/push'
import webPush from './zh-TW/web-push'
import sms from './zh-TW/sms'
import ums from './zh-TW/ums'
import email from './zh-TW/email'
import whatsapp from './zh-TW/whatsapp'
import about from './zh-TW/about'
import contact from './zh-TW/contact'
import signin from './zh-TW/accounts/signin'
import signup from './zh-TW/accounts/signup'
import checkCaptcha from './zh-TW/accounts/checkCaptcha'
import organize from './zh-TW/accounts/organize'
import forgetPwd from './zh-TW/accounts/forgetPwd'
import resetPwd from './zh-TW/accounts/resetPwd'
import pwdUpdated from './zh-TW/accounts/pwdUpdated'
import license from './zh-TW/license'
import docs from './zh-TW/docs'
import blog from './zh-TW/blog'
import twofa from './zh-TW/accounts/2fa'
export default {
  header: {
    home: {
      name: '主頁',
    },
    products: {
      name: '產品',
      typeNameList: ['單通道業務', '營銷', 'AIGC 服務'],
      sub: ['Omnichannel Messaging', 'App Push', 'Web Push', 'SMS', 'Email', 'WhatsApp Business API'],
    },
    docs: {
      name: '檔案',
    },
    aboutUs: {
      name: '關於我們',
    },
    blog: {
      name: '部落格',
    },
    lang: {
      name: '繁體中文',
      sub: {
        zh_TW: '繁體中文',
        zh_CN: '简体中文',
        en_US: 'English',
        ja_JP: 'にほんご',
        th_TH: 'ภาษาไทย',
      },
    },
    user: {
      login: '登入',
      signup: '註冊',
      service: '服務中心',
      account: '賬號資訊',
      logout: '登出',
    },
  },
  footer: {
    title: '全球領先的客戶互動平台',
    remark: 'Copyright © 2023 Metaverse Cloud.  All rights reserved.',
    contactUs: {
      name: '聯繫我們',
      sub: ['Sales@engagelab.com', '合作夥伴'],
    },
    aboutUs: {
      name: '關於我們',
      sub: ['公司簡介', '部落格'],
    },
    company: {
      name: '公司',
      sub: ['免費試用', '聯絡我們'],
    },
    products: {
      name: '產品',
      sub: ['Omnichannel Messaging', 'App Push', 'Web Push', 'SMS', 'Email', 'WhatsApp Business API'],
    },
    support: {
      name: '技術支援',
      sub: ['開發者檔案', '隱私協定', '服務協定'],
      content: 'Contact us for further product and service information',
    },
    privacy: {
      text: '單擊“接受所有 Cookie”，即表示您同意在您的設備上存儲 Cookie，以增強網站導航、分析網站使用情況，幫助我們改善用戶體驗。要詳細了解我們使用的不同 Cookie，請查看我們的',
      policy: 'Cookie 政策',
      accept: '接受',
      cancel: '取消',
    },
    chart: {
      tip: '您需要接受Cookie隱私政策才能發起咨詢！',
    },
  },
  error: {
    404: {
      title: '無法訪問頁面',
      text: '抱歉，找不到您要訪問的頁面',
    },
    500: {
      title: '伺服器異常',
      text: '伺服器出錯了，請稍後刷新頁面',
    },
    mainBtn: '返回首頁',
    subBtn: '刷新頁面',
  },
  index,
  push,
  webPush,
  sms,
  ums,
  email,
  whatsapp,
  about,
  contact,
  signin,
  signup,
  checkCaptcha,
  organize,
  forgetPwd,
  resetPwd,
  pwdUpdated,
  license,
  blog,
  docs,
  twofa,
}
