export default {
  banner: {
    title: '實時精準的web通知',
    subTitle:
      '藉助 EngageLab 網站推播通知服務，無需使用行動應用程式，就能隨時向網站訂閱者推播行銷訊息和服務通知，有效改善用戶活躍度和轉化率',
    mainBtn: '免費試用',
    subBtn: '聯係銷售',
  },
  tab: {
    title: '應用場景',
    sub: [
      {
        title: '優惠促銷通知',
        descTitle: '網站推播通知服務根據用戶標籤和等級，向用戶傳送不同數量的優惠券，提升用戶互動率',
        desc: ['自動向新註冊用戶推播歡迎資訊', '在用戶付款猶豫期間，向用戶傳送優惠券'],
      },
      {
        title: '內容更新的提醒',
        desc: ['如有新公布內容，如創作者、渠道、部落格和產品相關內容，將根據訂閱偏好，向用戶傳送更新內容的瀏覽器推播通知'],
      },
      {
        title: '服務變動通知',
        desc: [
          '根據用戶在系統中的服務狀態變更，網站推播通知服務將向用戶推播重要的網站通知資訊。例如，付款、物流進度、帳號變更、投訴意見反映和好友資訊等',
        ],
      },
      {
        title: '無效訂單的轉化',
        desc: ['當購物車內商品長時間未付款或訂單被取消時，網站推播通知服務會向用戶傳送抵用券或折扣券，提升訂單轉化率'],
      },
    ],
  },
  reason: [
    {
      title: '媲美原生的通知體驗',
      desc: [
        '網站推播通知服務不僅能從 APP 分離，還與多款智慧手機上的原廠行動推播功能類似。此外，Windows 和 MacOS 平台亦支援桌面推播通知服務',
      ],
    },
    {
      title: '支持主流瀏覽器',
      desc: ['EngageLab 瀏覽器推播通知服務支援 Chrome、Safari 和火狐等多款主流瀏覽器。大多數用戶皆能收到網站推播通知'],
    },
    {
      title: '增加訂閱者',
      desc: ['通過engagelab的引導提示功能，在合適的時機向網站用戶傳達訂閱通知消息的價值，可提升約70%的通知訂閱率。'],
    },
  ],
  push: {
    title: '嘗試快速發送1條WEB通知',
    step: ['輸入您要發送的消息內容', '點擊「允許」，授於網站通知權限', '展示通知效果'],
    send: '發送通知',
  },
}
